.page-heading {
	display: flex;
	flex-flow: column;
	border-bottom: 1px solid var(--grey-200);
	width: 100%;
	font-weight: 700;
	padding: 40px 0 20px;
	margin-bottom: 20px;
}

.page-heading__project {
	font-size: 14px;
	color: var(--grey-400);
	margin-bottom: 3px;
}

.page-heading__title {
	text-align: left;
	font-size: 21px;
	color: var(--primary-main);
}
