.eng-task-m__form__input-c {
	margin-bottom: 10px;
}

.eng-task-m__footer {
	gap: 13px;
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
}

.eng-task-m__loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
}
