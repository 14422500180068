/*
t = table
*/

.t {
	margin-top: 30px;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	width: 100%;

	flex-grow: 1;
	min-width: 2000px;
}

.t--compact {
	min-width: 100%;
}

/* table header */
.t__header {
	height: 35px !important;
	background-color: var(--company-color) !important;
	color: #fff !important;
	border-bottom: 1px solid var(--grey-200);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	padding-left: 10px;
	padding-right: 10px;
}

/* table row */
.t__row {
	padding-left: 10px;
	flex: 1;
	background-color: var(--white);
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	height: inherit;
	height: 20px;
	max-height: 300px !important;
	transition: box-shadow 0.3s ease-in-out 0.2s, min-height 0.3s ease-in-out 0.2s, margin-bottom 0.3s ease-in-out 0.2s, margin-top 0.3s ease-in-out 0.2s !important;
	overflow-y: scroll;
}

.t__task-row {
	padding-left: 30px;
}

.t__row--stripe {
	background-color: var(--grey-100) !important;
}

.t__row:hover {
	cursor: pointer;
	background-color: rgba(var(--company-color-rgb), 0.3) !important;
	box-shadow: inset 0 0px 0px 1px var(--company-color);
}

.t__row:hover .t__row__item--name {
	color: var(--grey-600);
}

.t__row__divider {
	margin-block-start: 0.3em;
	margin-block-end: 0.3em;
	border: 0.1px solid var(--grey-100);
}

.t-summary__body__item--stripe {
	background-color: var(--grey-200);
}

/* header and row items */
.t__header__item,
.t__row__item,
.t__col__item {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-weight: 700;
	color: var(--primary-main);
	font-size: 12px;
	display: flex;
	align-items: center;
	flex: 1;
}

.t__col__item {
	flex-flow: row wrap;
}

.t__header__item {
	color: var(--white);
}

.t__row__item--flow-state {
	display: flex;
	justify-content: space-between;
	margin-right: 10px !important;
}

/* client-summary */
.t__row__item--summary {
	color: #4a4a4a;
	background-color: white;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	height: inherit;
	min-height: 25px;
	max-height: 300px !important;
	overflow-y: hidden;
	transition: box-shadow 0.3s ease-in-out 0.2s, min-height 0.3s ease-in-out 0.2s, margin-bottom 0.3s ease-in-out 0.2s, margin-top 0.3s ease-in-out 0.2s !important;
}

.t__row__item--summary:hover {
	box-shadow: 0px 0px 0px 4px var(--grey-100);
	/* overflow: scroll; */
	cursor: pointer;
	height: fit-content;
	margin-top: 10px;
	margin-bottom: 10px;
	opacity: 0.9;
}
