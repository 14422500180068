.toggle {
	height: 30px;
	min-width: 35px;
	color: var(--primary-main) !important;
	cursor: pointer;
	border-radius: 4px;
	font-size: 16px;
	background-color: transparent;
	border: 1px solid var(--grey-300);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: 700;
	padding: 0 10px;
}

.toggle--active {
	background-color: var(--grey-200) !important;
	transition: 0.2s;
}

.toggle__label {
	font-size: 12px;
	font-weight: 700;
	font-family: "Modern Era";
}

.toggle__icon {
	margin-right: 15px;
}
