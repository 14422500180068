.email-sent {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	height: 100%;
	padding: 0 200px;
}

.email-sent__check-icon {
	width: 70px;
}

.email-sent__title {
	font-size: 24px;
	font-weight: 700;
}

.email-sent__subtitle {
	font-size: 18px;
	width: fit-content;
	width: 350px;
	color: var(--primary-main);

	text-align: center;
}

.email-sent__ok-btn {
	width: 100%;
}
