.login {
	width: 100%;
	height: 80vh;
	display: flex;
	background-color: #fff;
	height: 1024px;
}

.login__body {
	display: flex;
	flex-flow: row;
	flex: 1;
	width: 100%;
}

.login__left-side {
	display: flex;
	flex-flow: column;
	flex: 1;
	width: 100%;
	padding: 50px;
}

.login__body__srj-logo {
	width: 80px;
}

.login__body__title {
	font-weight: 700;
	font-size: 36px;
	color: #000000;
	margin-top: 66px;
}

.login__body__subtitle {
	font-size: 21px;
	color: #797979;
	margin-top: 13px;
}

.login__body__form {
	display: flex;
	flex-flow: column;
	width: 100%;
	margin-top: 38px;
	gap: 20px;
}

.login__body__form__input-c-email {
	width: 100%;
	margin-bottom: 10px;
}

.login__body__form__btn {
	background: var(--company-color);
	border-radius: 8px;
	height: 54px;
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	margin: 30px 0;
}

.login__body__form__btn:hover {
	cursor: pointer;
	opacity: 0.9;
}

/* right side */
.login__right-side {
	width: 50%;
	height: 100%;
	background: var(--company-color);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 20px;
}

@media screen and (max-width: 1000px) {
	.login__right-side {
		display: none;
	}
}

.login__right-side__illustration {
	width: 100%;
	margin-top: 150px;
}

.login__body__form__inputs__reset-pass-link {
	position: relative;
	bottom: 100px;
	transform: translateY(-50%);
	padding: 0 0px;
	z-index: 1;
}

.login__body__form__inputs__reset-pass-link__text {
	position: absolute;
	right: 0;
	font-size: 14px;
	color: var(--company-color);
	text-decoration: underline;
}
