.workflow-notes-editor {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
}

.workflow-notes-editor-input {
	flex-grow: 1;
}

.workflow-notes-editor__button {
	font-size: 14px;
	margin-left: 5px;
}

.workflow-notes-editor__button:hover {
	color: #395395;
}

.workflow-notes-editor-save {
	font-size: 14px;
	margin-left: 5px;
}

.workflow-notes-editor-cancel {
	font-size: 14px;
	margin-left: 5px;
}

.test-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
}

.view-list {
	position: relative;
	margin-top: 30px;
	margin-right: 25px;
}

.view-list__content {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;

	padding: 15px;

	box-sizing: border-box;
	height: 650px;
	background-color: white;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);

	max-width: 200px;
}

.view-list__arrow {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: -20px;
	top: 5px;
	width: 40px;
	height: 40px;
	background-color: white;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.9);
	border-radius: 50px;
}

.view-list__arrow:hover {
	cursor: pointer;
	transition: 0.2s;
	color: white;
	background-color: var(--company-color);
}

.views-container__title {
	font-size: 18px;
	padding: 10px;
	color: #666666;
	font-weight: 700;
}

.views-container__actions {
	margin-top: auto;
	align-self: flex-end;
}

.views-container__list {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
	width: 100%;
}

.views-container__list__item {
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 35px;

	border-radius: 4px;

	padding: 0 15px;

	font-size: 14px;
	color: #666666;
	font-weight: 700;
}

.views-container__list__item--selected {
	color: #000;
	background-color: var(--grey-200);
	font-weight: 700;
}

.views-container__list__item:hover {
	transition: 0.2s;
	color: #000;
	background-color: #f2f2f2;
	font-weight: 700;
	cursor: pointer;
}

.views-container__list__item__action {
	display: none;
}

.views-container__list__item:hover > .views-container__list__item__action {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	padding-top: 1px;
	width: 24px;
	height: 24px;
}

.collapse-engagement {
	line-height: normal;
	margin-top: 10px;
	padding-right: 5px;
}

.engagement-task {
	padding-left: 20px;
}

.engagement-divider {
	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
	border: 0.5px solid #e7e7e7;
}

.task-progress {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	flex: 1;
}

.task-progress__ratio {
	margin-right: 10px;
	font-size: 12px;
}

.task-progress__bar {
	width: 100%;

	height: 15px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	background-color: white;
	border-radius: 15px;
	overflow: hidden;
	border: 1px solid #e7e7e7;
}

.task-progress__bar__completed {
	height: 20px;
	width: 0%;
	background-color: rgb(127, 196, 127);
}

.kb-container {
	margin-left: 10px;
	margin-top: 30px;

	/* zoom: 130% !important; */
	width: 100%;
	height: calc(100vh);
}

.kb-item {
	background-color: var(--primary-color);
}

.pl-10 {
	padding-left: 10px;
}

.engagements__total-count {
	margin-right: 10px;
}

/* engs = enagements */
.engs {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.engs__header {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
}

.engs__header__actions {
	display: flex;
	gap: 13px;
}

.engs__header__trello-toggle,
.engs__header__list-toggle {
	padding-top: 2px;
}

.engs__header__total {
	font-size: 16px;
	font-weight: 700;
	color: var(--primary-main);
	margin-right: 13px;
}

.engs__row__item--name,
.engs__header__item--name {
	flex-grow: 2 !important;
}

.engs__header__item--status,
.engs__row__item--status {
	flex-grow: 1.5 !important;
}

.engs__row__item__note-btn {
	height: 18px;
	font-size: 10px;
	font-weight: 700;
	border-radius: 0;
	border: none !important;
}

.engs__sort-icon {
	font-size: 10px;
}

.engs__row__item__name-chevron {
	margin-right: 5px;
}

.engs__row__item__name-value {
	margin-right: 5px;
}

.engs__row___item__checkbox {
	margin-right: 10px;
	margin-left: 10px !important;
}

.engs__task-row {
	padding-left: 27px !important;
}

.engs__header__item--task-prog,
.engs__row__item--task-prog {
	padding-right: 50px;
}
