.budgets__body {
	display: flex;
	flex-flow: column;
	justify-content: center;

	background-color: var(--grey-50);
	padding: 20px;
	height: auto;
	margin-top: 30px;
}

.budgets__body__title,
.budgets__body__item__content,
.budgets__body__item__task {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
	flex: 1;
}

.budgets__body__item__task {
	color: #000;
}

.budgets__body__item {
	font-weight: 700;

	height: 36px;
	padding: 12px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	background: white;
	border: 1.5px solid var(--grey-200);
}

.budgets__body__item__content {
	display: flex;
	flex-flow: column;
}

.budgets__body__item__content__val {
	font-weight: 700;
	display: flex;
	align-items: center;
	gap: 10px;
	color: #000;
}

.budgets__body__item__content__val--over {
	color: var(--red-400);
}

.budgets__body__item__content__val--under {
	color: var(--green-400);
}

.budgets__body__item__delete:hover,
.budgets__body__item__content__val__edit:hover {
	cursor: pointer;
	opacity: 0.8;
}

.budgets__body__hd {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.budgets__body__hd__title {
	font-size: 21px;
	font-weight: 700;
	color: var(--company-color);
}

.budgets__body__empty {
	display: flex;
	flex-flow: column;
	align-items: center;
	padding: 20px;
	color: var(--grey-700);
	font-size: 24px;
	font-weight: 400;
}

.budgets__body__item__content__val__input {
	border: none !important;
	border-bottom: 1px solid #000 !important;
	border-radius: 0 !important;
	height: 20px !important;
	padding-left: 0 !important;
}

.budgets__body__item__content__val__check:hover,
.budgets__body__item__content__val__delete:hover {
	cursor: pointer;
	opacity: 0.8;
}

.budgets__body__item__content__val--editing {
	width: 60%;
}

.budgets__body__item__content__val__text {
	min-width: 25%;
}

.budgets__body__item--total {
	border: none;
	background-color: var(--grey-50);
}
