.client-filter-modal {
	flex: 1;
}

.client-filter-modal__body {
	display: flex;
	justify-content: center;
	gap: 20px;
	padding: 20px;
}

.client-filter-modal__body__inputs {
	display: flex;
	flex-flow: column;
	flex: 1;
}

.client-filter-modal__more {
	display: flex;
	flex-flow: column;
	flex: 1;
}

.client-filter-modal__general__label {
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.client-filter-modal__input-c {
	margin-bottom: 10px !important;
}

.clear-filter-modal__footer {
	margin-top: 50px;
	display: flex;
	justify-content: flex-end;
	gap: 20px;
	padding: 10px;
}
