.etm {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.etm__fields {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	gap: 20px;
	padding: 20px;
}

.etm__fields__inputs {
	display: flex;
	flex-flow: column;
	gap: 20px;
}

.eng-modal-datepicker-container {
	display: flex;
	flex-flow: column;
}

/* TODO: will be resolved in datepicker pr */
.eng-modal-datepicker-title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.eng-modal-datepicker {
	padding: 0 15px;
	height: 31.6px;
	width: 100%;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: var(--white);
	box-sizing: border-box;
	font-weight: 700;
	font-family: "Modern Era";
	margin-bottom: 10px;
}

/*  */

.contacts-modal-input-box-container {
	margin-top: 20px;
}

/* assignments section */
.etm__tasks {
	display: flex;
	flex-flow: column;

	margin-top: 20px;
	padding: 0 20px 20px;
}

.etm__tasks__task-list {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.etm__tasks__cta {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}

.etm__tasks__label {
	padding: 0 0 6px 0;
	font-weight: 700;
	color: var(--primary-main);
	font-size: 14px;
	border-bottom: 1px solid var(--grey-200);
}

.etm__tasks__cta__title {
	margin: 20px 0px;
	font-size: 16px;
	font-weight: 700;
	color: var(--primary-main);
}

.etm__tasks__cta__button {
	margin-bottom: 20px;
}

.etm__tasks__cta__button:hover {
	cursor: pointer;
	opacity: 0.8;
}

.btn-group--delete-button {
	color: #ff6b6b;
	cursor: pointer;
	margin-top: 5px;
	margin-right: 10px;
}

/* Custom Board Styles for Task Templates */
.react-trello-board {
	height: 100% !important;
	overflow-x: hidden;
}

.react-trello-board > div {
	align-self: stretch;
	flex: 1 0 auto;
}

/*board styles override*/
.react-trello-board {
	height: 100% !important;
	background-color: transparent !important;
	animation: none !important;
}

.etm__tasks__task-list .smooth-dnd-container.horizontal .smooth-dnd-draggable-wrapper {
	width: 100%;
}

.etm__tasks__task-list .smooth-dnd-container.horizontal section > div {
	width: 100%;
}

/* Normal Modal Animations */
.modal__animation {
	animation: 300ms ease 0s 1 normal none running react-responsive-modal-modal-in;
}

/* Disable Modal Animations when dragging */
.modal__no-animation {
	animation: none;
}

/* Keyframes */
@keyframes react-responsive-modal-modal-in {
	0% {
		transform: scale(0.96);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

/* loader */

.etm-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 400px;
}
