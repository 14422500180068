.user-assigner-container {
	box-sizing: border-box;
	overflow: auto;

	position: fixed;
	z-index: 999999;

	background-color: #fff;
	box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
	border-radius: 8px;
	padding: 15px;

	height: 560px;
	width: 465px;

	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.user-assigner-container__component {
	overflow: auto;
}

.user-assigner-container__button-group {
	margin-top: auto;
	align-self: flex-end;
}
