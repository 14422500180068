.connections {
	display: flex;
	flex-flow: column nowrap;
	gap: 10px;
}

.connections__item {
	margin: 10px 0px;
	padding: 20px 40px;
	height: 100%;
	border-radius: 4px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	transition: all 0.4s cubic-bezier(0.25, 0.1, 0, 1);
	background-color: white;
	border: 1px solid var(--grey-200);
}

.connections__item__icon {
	margin-top: 3px;
	align-self: flex-start;
	color: var(--company-color);
}

.connections__item__text {
	margin-left: 15px;
}

.connections__item__text__title {
	font-size: 30px;
}

.connections__item__text__blurb {
	font-size: 20px;
	margin-top: 10px;
	color: #767676;
}

.connections__item:hover,
.connections__item:focus {
	color: white !important;
	background-color: #385395;
	cursor: pointer;
	transition: background 0.2s, border 0.2s, color 0.2s;
}

.connections__item:hover .connections__item__text__blurb {
	color: white !important;
	transition: background 0.2s, border 0.2s, color 0.2s;
}

.connections__item:hover .connections__item__icon {
	color: white;
	fill: white;
}
