/* 
t-f-m = task-filter-modal
s-d-container = search-dropdown-container
*/

.t-f-m__body__search {
	display: flex;
	flex-flow: row;
	align-items: flex-end;
	gap: 10px;
	flex: 1;
}

.t-f-m__body__s-d-container {
	flex: 20;
}

.t-f-m__body__select-all-btn {
	height: 30px;
	flex: 2;
}
