.date-input {
	display: flex;
	flex-flow: column;
	width: 100%;
}

.date-input__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.date-input__input {
	padding: 0 15px;
	display: flex;
	height: 30px;
	font-size: 14px;
	color: #000;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: #fff;
	box-sizing: border-box;
	font-weight: 700;
	font-family: "Modern Era";
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.date-input__input {
	width: 100%;
}
