.date-selector__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.date-selector__input {
	padding-left: 15px;
	height: 35px;
	width: 100%;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: var(--white);
	box-sizing: border-box;
	font-weight: 700;
	font-family: "Modern Era";
}

/* react datepicker styles */
.react-datepicker {
	font-family: "Modern Era";
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid #d1d1d1;
}

.react-datepicker__month {
	background-color: #fff;
}

.react-datepicker__day {
	font-size: 14px;
	padding: 1px;
	font-weight: 700;

	color: var(--grey-700);
	border-radius: 4px;
	background-color: var(--grey-200);
}

.react-datepicker__day--selected {
	background-color: var(--company-color) !important;
	color: var(--white);
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: var(--company-color) !important;
}

.react-datepicker__header {
	background-color: #fff;
	border-bottom: 1px solid var(--grey-200);
}

.react-datepicker__day-names {
	font-size: 14px;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	padding-left: 25px;
	padding-right: 25px;
	font-weight: 700;
}

.react-datepicker__current-month {
	font-size: 14px;
	display: none;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	background-color: hsl(0, 0%, 100%);
	border: 1px solid var(--grey-200);
	border-radius: 4px;
	padding: 4px;

	display: flex;
	flex-flow: row-reverse;
	align-items: flex-start;

	font-size: 14px;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	font-size: 16px;
	padding: 4px 5px;
	background-color: hsl(0, 0%, 100%);
	border: 1px solid var(--grey-200);
	font-size: 14px;
	font-weight: 700;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: var(--grey-200);
	transition: 0.2s;
}

.--selected_month,
.react-datepicker__year-option--selected_year {
	background-color: var(--company-color);
	color: #fff;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	top: 4px;
	left: 10px;
}

.react-datepicker__month-read-view {
	margin-right: 10px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
	display: none;
}

.react-datepicker__header__dropdown {
	display: flex;

	flex: 1;
	gap: 10px;
	margin: 0 20px;
}

.react-datepicker__time-container {
	background-color: var(--white);
	border: 1px solid var(--grey-200);
	border-radius: 4px;
	width: 100%;
}

.react-datepicker__time {
	font-size: 14px;
	padding: 5px;
}

.react-datepicker__time-list {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.react-datepicker__time-list-item {
	border-radius: 10px;
	align-items: center;
	justify-content: center;
	display: flex;
	margin-bottom: 5px;
}

.react-datepicker__time-list-item:hover {
	border-radius: 10px;
}

.react-datepicker__time-list-item--selected {
	border-radius: 10px;
	align-items: center;
	display: flex;
}

.react-datepicker-time__header {
	font-size: 14px;
}

.react-datepicker__time {
	margin-top: 10px;
}

.react-datepicker__month-container {
	width: 100%;
	flex: 1;
	border: none;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container,
.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
	display: flex;
	flex: 1;
	margin: 0;
}

.react-datepicker__month-read-view,
.react-datepicker__year-read-view {
	font-weight: 700;
}

.react-datepicker__navigation--years-upcoming,
.react-datepicker__navigation--years-previous {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	display: inline-block;
	cursor: pointer;
}

.react-datepicker__navigation--years-upcoming {
	border-bottom: 10px solid var(--grey-700);
}

.react-datepicker__navigation--years-previous {
	border-top: 10px solid var(--grey-700);
}
