.tasks {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.tasks__header {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	gap: 13px;
}

.tasks__header__trello-toggle,
.tasks__header__list-toggle {
	padding-top: 2px;
}

.tasks__header__total {
	font-size: 16px;
	font-weight: 700;
	color: var(--primary-main);
}

/* TODO: below needs to refactored to its coresponding component */

.task-card {
	width: 350px;
	background-color: white;
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	border: 2px solid white;
}

.task-card:hover {
	cursor: pointer;
	border: 2px solid var(--primary-main);
	transition: 0.2s;
}

.task-card__label {
	font-size: 12px;
}

.task-card__title {
	font-size: 14px;
	font-weight: 900;
}

.task-card__description {
	font-size: 12px;
	margin-top: 10px;
}

.task-card__eng-name {
	font-size: 12px;
}

.task-card__due-date {
	align-self: flex-end;
	font-size: 12px;

	color: var(--primary-color);
	background-color: white;
	border: 1px solid var(--primary-color);
	border-radius: 20px;

	padding: 5px 10px;
	margin-top: 10px;
}

.task-card__divider {
	margin: 10px 0px;
	min-width: 320px;
	min-height: 1px;
	max-height: 1px;
	align-self: center;
	background-color: #d7d7d7;
}

.task-card__dates {
	display: flex;
	flex-flow: row nowrap;
	align-self: flex-end;
}

.task-card__dates__item {
	margin-left: 10px;
	align-self: flex-end;
	font-size: 12px;

	color: var(--primary-color);
	background-color: white;
	border: 1px solid var(--primary-color);
	border-radius: 20px;

	padding: 5px 10px;
	margin-top: 10px;
}

.task-modal__engagement {
	color: #385395;
	background-color: white;
	border: 1px solid #385395;
	border-radius: 20px;

	padding: 10px 20px;
	margin-top: 10px;

	width: initial;

	max-width: 500px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.task-modal-section-1-container {
	margin-right: 40px;
}

.task-modal-form-section-3 {
	margin-top: 10px;
	margin-bottom: 20px;
}

.task-modal-form {
	display: flex;
	flex-flow: column;
	z-index: 1;
	padding: 20px;
}

.task-modal-form-container-1 {
	margin-top: 20px;
	display: flex;
	flex-flow: row wrap;
}

.task-modal-form-container-2 {
	margin-left: 100px;
}

.task-modal-form-container-3 {
	margin-top: 10px;
}

.task-modal-section-1-container {
	display: flex;
	flex-flow: column;
}

.task-modal-input-box-container {
	margin-top: 20px;
	margin-left: 0;
}

.task-modal-notes {
	margin-bottom: 20px;
}

.task-modal-notes-input {
	margin-right: 100px;
	resize: vertical;
	height: 65px;
	width: 560px;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

.task-modal {
	display: flex;
	flex-flow: column nowrap;
}

.task-modal__description {
	height: 150px;
}

.task-kanban-container {
	margin-top: 30px;
	margin-left: 0px;

	width: 100%;

	overflow: auto;
}

.tasks__row-item--client-name,
.tasks__header__item--client-name {
	flex-grow: 2 !important;
}
