.transactions-tool {
	display: flex;
	flex-flow: column;
	gap: 20px;
	padding: 0 25px 30px 270px;
}

.transactions-tool__heading {
	margin-bottom: 0;
}
