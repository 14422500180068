.checkbox {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: fit-content;
}

.checkbox:hover {
	cursor: pointer;
	opacity: 0.8;
}

.checkbox--small {
	margin-left: 0px !important;
	margin-top: 0px !important;
}

.checkbox__title {
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
	margin-left: 10px;
}

.checkbox__input {
	height: 18px;
	width: 18px;
	flex-shrink: 0;
	background-color: #fff;
	border: 1px solid var(--primary-main);
	border-radius: 3px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.checkbox__input--small {
	background-color: white;
	height: 12px !important;
	width: 12px !important;
}

.checkbox__input--selected {
	background-color: #000;
}

.checkbox__input:hover {
	opacity: 0.9;
	cursor: pointer;
}
