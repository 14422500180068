.button {
	height: 35px;
	width: fit-content;
	padding: 0px 10px;
	color: #000;
	cursor: pointer;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 700;
	border: 1px solid var(--grey-300);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.button:hover {
	cursor: pointer;
	opacity: 0.8;
	transition: 0.2s;
	border-color: var(--company-color);
	color: var(--company-color);
}

.button--compact {
	height: 30px;
}

.button--primary {
	background-color: var(--company-color);
	color: var(--white);
}

.button--primary:hover {
	color: var(--white);
}

.button--secondary {
	background-color: var(--white);
	color: var(--primary-main);
}

.button__icon--has-text {
	margin-left: 15px;
	margin-top: 3px;
}

.button--disabled,
.button--disabled:hover {
	opacity: 0.5;
}
