.notes-container {
	box-sizing: border-box;
	overflow: visible;

	position: fixed;
	z-index: 999;

	background-color: #fff;
	box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
	border-radius: 8px;
	padding: 15px;

	height: 560px;
	width: 465px;

	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.notes-container__name {
	margin-bottom: 20px;
}

.notes-container__loader {
	align-self: center;
}

.notes-container__actions {
	width: 100%;
}

.floating-notes {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: flex-start;
	flex: 1;

	margin-top: 20px;
	overflow: auto;

	width: 100%;
}

.floating-notes--no-flex {
	flex: none;
}

.floating-notes__blurb {
	font-size: 18px;
	color: var(--primary-color);
	margin-bottom: 20px;
}

.floating-notes__title {
	align-self: flex-start;
}

.floating-notes__item {
	font-size: 16px;
	border-radius: 8px;
	border: 2px solid var(--primary-color);
	padding: 15px;
	margin-top: 15px;

	width: calc(100% - 40px);

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.floating-notes__item__metadata {
	margin-top: 10px;
	font-style: italic;
	color: grey;
	font-size: 14px;
	margin-left: auto;
}

.btn-group--center {
	align-self: center;
}

.btn-group--spacing {
	margin-top: 10px;
}

.btn--no-margin {
	margin: 0 !important;
}
