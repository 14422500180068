.text-area-field {
	display: flex;
	flex-flow: column;
	resize: vertical;
	width: 100%;
	min-height: 100px;
}

.text-area-field__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.text-area-field__input--large {
	width: 600px;
	height: 200px !important;
}

.text-area-field__input {
	min-height: 45px;
	padding: 15px 18px;
	font-size: 14px;
	color: #000;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: var(--white);
	font-weight: 700;
	/* TODO: find a replacement so we won't have to explicitly call the font in this css file */
	font-family: "Modern Era";
}
