.eng-templates__header {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.eng-templates__header__tabs {
	display: flex;
	gap: 13px;
}

.eng-templates__header__actions {
	display: flex;
	align-items: center;
	gap: 13px;
}
