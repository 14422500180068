.task-modal {
}

.task-modal__body {
	display: flex;
	flex-flow: column;
	padding: 20px;
}

.task-modal__body__input {
	margin-bottom: 10px;
}

.task-modal__body__engagement {
	color: var(--primary-color);
	background-color: white;
	border: 1px solid var(--primary-color);
	border-radius: 20px;

	padding: 10px 20px;
	margin-top: 10px;

	width: initial;

	max-width: 500px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.task-modal__body-datepicker {
	height: 45px;
	width: 280px;
	padding-right: 20px;
	padding-left: 20px;
	font-size: 18px;
	color: #666666;
	border-radius: 30px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
	box-sizing: border-box;
}

.task-modal__body-section-1-container {
	margin-right: 40px;
}

.task-modal__body-form-section-3 {
	margin-top: 10px;
	margin-bottom: 20px;
}

.task-modal__body-form {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
	padding: 20px;
}

.task-modal__body-form-container-1 {
	margin-top: 20px;
	display: flex;
	flex-flow: row wrap;
}

.task-modal__body-form-container-2 {
	margin-left: 100px;
}

.task-modal__body-form-container-3 {
	margin-top: 10px;
}

.task-modal__body-section-1-container {
	display: flex;
	flex-flow: column;
}

.task-modal__body-input-box-container {
	margin-top: 20px;
	margin-left: 0;
}

.task-modal__body-notes {
	margin-bottom: 20px;
}

.task-modal__body-notes-input {
	margin-right: 100px;
	resize: vertical;
	height: 65px;
	width: 560px;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

.task-modal__body__description {
	height: 150px;
}
