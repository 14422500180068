.searchable-dropdown {
	display: flex;
	flex-flow: column;
	width: 100%;
	position: relative;
	color: var(--primary-main);
}

.searchable-dropdown__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.searchable-dropdown__input {
	padding-left: 15px;
	height: 35px;
	width: 100%;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: var(--white);
	box-sizing: border-box;
	font-weight: 700;
	font-family: "Modern Era";
}

.searchable-dropdown__options {
	position: absolute;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;

	top: 60px;
	left: 10px;
	max-height: 400px;
	width: 100%;

	z-index: 10;

	background-color: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 10px;

	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	list-style-type: none;
	cursor: pointer;
	overflow: scroll;
	overflow-x: hidden;
	margin-left: -10px;
}

.searchable-dropdown__options--no-title {
	top: 55px !important;
}

.searchable-dropdown__options__item {
	margin-bottom: 4px;
	padding: 10px 15px;
	width: calc(100% - 30px);
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	font-weight: 700;
}

.searchable-dropdown__options__item--hover {
	transition: 0.2s;
	color: #000;
	background-color: #ececec;
}
