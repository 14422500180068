/**
	c-m: contact modal
	hd: header
*/
.c-m {
	padding: 0 !important;
	display: flex;
	flex-flow: column;

	height: 100%;
	width: 100%;
}

.c-m__hd {
	padding: 15px;
	border-bottom: 1px solid var(--grey-200);
}

.c-m__hd__title {
	font-size: 21px;
	color: #666666;
	font-weight: 700;
}

.c-m__body__inputs {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	width: 100%;
}

/* TODO: hot fix: add margin-top to each input .. not good practice atm. */
.c-m__body__inputs > * {
	margin-top: 10px;
}

.c-m__body {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
	padding: 30px;
}

.c-m__body__inputs__is-primary {
	margin-top: 10px;
}

.c-m__footer {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-end;
	border-top: 1px solid var(--grey-200);
	padding: 20px;
	gap: 13px;
}

/* search contacts section */
.c-m__body__c-search {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: flex-end;
	padding: 20px;
	background-color: var(--grey-200);
}

.c-m__body__c-search__input {
	display: flex;
	align-items: flex-end;
	gap: 10px;
}

.c-m__body__c-search__results {
	margin-top: 15px;
	display: flex;
	flex-flow: column nowrap;
	border-radius: 10px;
	border: 1px solid var(--grey-200);
	align-self: center;
	overflow: auto;
	width: 100%;
}

.c-m__body__c-search__results__item {
	background-color: var(--white);
	display: flex;
	flex-flow: column nowrap;
	padding: 10px;
	border: 1px solid rgba(57, 83, 149, 0.1);
	border-radius: 4px;
	margin-bottom: 10px;
	color: var(--primary-main);
	font-weight: 700;
}

.c-m__body__c-search__results__item:hover {
	cursor: pointer;
	opacity: 0.8;
}

.c-m__body__c-search__results__item__name {
	font-weight: 700;
}

.c-m__body__c-search__results__item__email {
	opacity: 0.8;
	font-size: 14px;
	margin-top: 3px;
}

.c-m__body__c-search__results__item__phone {
	opacity: 0.8;
	font-size: 14px;
	margin-top: 3px;
}

.c-m__body__c-search__results__item__sin {
	opacity: 0.8;
	font-size: 14px;
	margin-top: 3px;
}

/* remove contact modal */
/*
b - body
t - title
subt - subtitle
cont - container
*/
.c-m__remove-contact {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.c-m__remove-contact__b__t {
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}

.c-m__remove-contact__b__subt {
	font-weight: 400;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;
}

.c-m__remove-contact__b__footer {
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.c-m__footer__mail-icon {
	margin-left: 10px;
}
