.card {
	height: 52px;

	padding-left: 12px;
	padding-right: 12px;

	width: calc(100% - 40px);
	display: flex;

	justify-content: space-between;
	align-items: center;

	border: 1.5px solid #dfdfdf;
}

.card__title {
	font-weight: 700;
	font-size: 16px;
	margin-right: 20px;
}
