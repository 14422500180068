.eng-task-card {
	min-height: 62px;
	padding-left: 12px;
	padding-right: 12px;
	border: 1.5px solid var(--grey-300);
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.eng-task-card:hover {
	cursor: pointer;
	opacity: 0.8;
}

/* remove task */
.eng-task-card__remove-task {
	margin-bottom: 46px;
	margin-left: 15px;
}

.eng-task-card__remove-task:hover {
	cursor: pointer;
}

.eng-task-card__title {
	font-size: 14px;

	color: var(--primary-main);
}

.eng-task-card__remove-task-icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.eng-task-card__content {
	width: 90%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.eng-task-card__content__title {
	font-size: 14px;
	color: var(--primary-main);
	font-weight: 400;
}

.eng-task-card__content__divider {
	border-bottom: 1px solid var(--grey-200);
	margin: 5px 0 5px 0;
	width: 100%;
}

.eng-task-card__content__assigned {
	display: flex;
	align-items: center;
	gap: 4px;
}

.eng-task-card__content__assigned__label,
.eng-task-card__content__assigned__value {
	font-size: 14px;
	color: var(--primary-main);
	font-weight: bold;
}
