.react-datepicker-wrapper {
	display: block;
}

/* INLINE STATUS DROPDOWN - Styling ----------------------------------------- */

.in-line-status-dropdown-container {
	width: 90%;
	overflow: hidden;
}

.in-line-status-dropdown {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	padding-right: 15px;
	font-size: 15px;
}

.in-line-select {
	width: 100%;
	height: 150px;
	border: solid 2px #ababab;
	border-radius: 8px;
	outline: none;
}

.in-line-select-option {
	font-size: 14px;
	font-weight: lighter;
	padding: 2px;
}

/* STATUS DROPDOWN SECTION ----------------------------------------- */
.status-dropdown-container {
	width: 100%;
	position: relative;
	border-radius: 30px;
	-webkit-user-select: none;
}

.status-dropdown-item {
	text-align: left;
	padding: 13px 20px;
}

.status-dropdown-display {
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	border-left: 0.5px solid #ebeef4;
	border-right: 0.5px solid #ebeef4;
	border-bottom: 0.5px solid #ebeef4;
	cursor: pointer;
	font-size: 18px;
	background-color: #eaedf4;
	font-weight: 200;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.3);
	list-style-type: none;
	max-height: 200px;
	overflow: scroll;
	padding-inline-start: 0px;
	-webkit-user-select: none;
	position: absolute;
	margin-top: 0px;
	min-width: 329px;
	max-width: 329px;
	z-index: 1;
}

.status-dropdown-display-change {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	cursor: pointer;
	padding-right: 20px;
	padding-left: 20px;
	height: 45px;
	min-width: 280px;
	max-width: 280px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* width: 330px;
  padding-top: 13px;
  padding-bottom: 13px; */
	padding-right: 20px;
	padding-left: 20px;
	font-size: 18px;
	color: #666666;
	border-radius: 5px;
	border: 1px solid #dfdfdf;
	background: #fff;
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
}

.status-dropdown-item:hover {
	opacity: 0.7;
}

.status-dropdown-item-option {
	display: flex;
	width: 100%;
	color: #666666;
	text-decoration: none;

	font-size: 18px;
}

/* .status-dropdown li:last-child {
  border-bottom: none;
} */

/* STATUS DROPDOWN SECTION ----------------------------------------- */
.status-dropdown-display-container {
	min-width: 280px;
	max-width: 280px;
	height: 45px;
	cursor: pointer;
	/*  padding-top: 13px;
  padding-bottom: 13px; */
	padding-right: 20px;
	padding-left: 20px;
	font-size: 18px;
	color: #000;
	border-radius: 6px;
	border: 1px solid #dfdfdf;
	background: #fff;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row nowrap;
	font-weight: 700;
}

.dropdown-alternate {
	width: 100% !important;
}

/* DEFAULT DROPDOWN SECTION ----------------------------------------- */

.dropdown-container {
	cursor: pointer;
	font-size: 18px;
	color: #666666;
}

.dropdown-container-button {
	display: flex;
	align-items: center;

	color: #000;
	font-size: 12px;
	background-color: #fff;
	font-weight: 200;
	border: 1px solid #dfdfdf;

	display: flex;
	align-items: center;
	border-radius: 4px;
	height: 30px;
	padding-left: 14px;
	padding-right: 14px;
}

.dropdown-container-button-chevron {
	margin-left: 10px;
	display: flex;
	align-items: center;
}

.dropdown-container-button__val {
	font-weight: 700;
}

.dropdown-list {
	z-index: 10;
	margin-top: 5px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 14px;
	background-color: #fff;
	font-weight: 200;
	border: 1px solid #f5f5f5;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	list-style-type: none;
	position: absolute;
	max-height: 400px;
	overflow: scroll;
}

.dropdown-list-item {
	padding: 7px 15px 7px 15px;

	/* border-bottom: 1px solid #e5e5e5; */
	width: 100%;
	color: #000;
	text-decoration: none;

	font-size: 12px;
}

.dropdown-list-item:hover {
	transition: 0.2s;
	color: #000;
	background-color: #ececec;
}

/*  */

.alternate-dropdown-display {
	border-left: 0.5px solid #ebeef4;
	border-right: 0.5px solid #ebeef4;
	border-bottom: 0.5px solid #ebeef4;
	cursor: pointer;
	font-size: 18px;
	background-color: #eaedf4;
	font-weight: 200;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.3);
	list-style-type: none;
	max-height: 200px;
	overflow: scroll;
	padding-inline-start: 0px;
	-webkit-user-select: none;
	position: absolute;
	margin-top: 0px;
	min-width: 280px;
	max-width: 280px;
	z-index: 1;
	background-color: #fff;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.alternate-dropdown-item {
	padding: 5px 15px 5px 15px;

	min-height: 30px;
	width: 100%;
	overflow-x: hidden;

	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-start;
}

.alternate-dropdown-item:first-child {
	padding-top: 10px;
}

.alternate-dropdown-item:hover {
	color: #000;
	background-color: #ececec;
}

.alternate-dropdown-item-option {
	display: flex;
	width: 100%;
	text-decoration: none;

	font-size: 18px;
	color: #000;
	font-weight: 700;
}

/*  */
.dropdown-alternate-container {
	max-width: 280px;
}

.dropdown-title {
	max-width: 280px;
	display: flex;
	flex-flow: column;
	margin-top: 20px;
	margin-bottom: 6px;
	font-size: 18px;
	color: #666666;
	text-align: left;
	font-weight: 300;
	margin-left: 5px;
	font-weight: 700;
}
