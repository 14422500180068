.engagement-card {
	width: 350px;
	background-color: var(--white);
	padding: 15px;
	border-radius: 4px;
	margin-bottom: 10px;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	border: 2px solid var(--white);
}

.engagement-card:hover {
	cursor: pointer;
	border: 2px solid var(--primary-main);
	transition: 0.2s;
}

.engagement-card__label {
	font-size: 12px;
}

.engagement-card__title {
	font-size: 14px;
	font-weight: 900;
}

.engagement-card__title:hover {
	color: var(--company-color);
}

.engagement-card__description {
	font-size: 12px;
	margin-top: 10px;
}

.engagement-card__eng-name {
	font-size: 12px;
}

.engagement-card__eng-flow-state {
	align-self: flex-end;
	margin-top: 10px;
	font-size: 12px;
	padding: 5px 8px 4px 8px;
	border-radius: 15px;
}

.engagement-card__due-date {
	align-self: flex-end;
	font-size: 12px;
	color: var(--primary-main);
	background-color: var(--white);
	border: 1px solid var(--primary-main);
	border-radius: 20px;
	padding: 5px 10px;
	margin-top: 10px;
}

.engagement-card__divider {
	margin: 10px 0px;
	min-width: 320px;
	min-height: 1px;
	max-height: 1px;
	align-self: center;
	background-color: #d7d7d7;
}

.engagement-card__dates {
	display: flex;
	flex-flow: row nowrap;
	align-self: flex-end;
}

.engagement-card__dates__item {
	margin-left: 10px;
	align-self: flex-end;
	font-size: 12px;
	color: var(--primary-main);
	background-color: var(--white);
	border: 1px solid var(--primary-main);
	border-radius: 20px;
	padding: 5px 10px;
	margin-top: 10px;
}

.engagement-card__notes {
	margin-top: 10px;
	font-size: 14px;
}

.engagement-card__notes:hover {
	color: var(--company-color);
}

.engagement-card__tasks {
	margin-top: 10px;
	border-radius: 4px;
	background-color: var(--grey-200);
	width: 100%;
	padding: 10px;
}

.engagement-card__tasks__no-tasks {
	font-size: 12px;
}

.engagement-card__tasks__item {
	font-size: 12px;
	margin-top: 5px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: space-between;
}
