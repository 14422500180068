.bookkeeping-tool__body {
	display: flex;
	flex-flow: column;
	gap: 20px;
}

.clients__header {
	display: flex;
	align-items: center;
}

.classification-dropdown {
	position: absolute;
	z-index: 1000;
	background: white;
	border: 1px solid #ccc;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	min-width: 150px;
	padding: 5px 0;
	overflow: visible;
}

.dropdown-item {
	padding: 8px 12px;
	cursor: pointer;
	transition: background-color 0.2s;
}
