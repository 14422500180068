.status-container {
	box-sizing: border-box;
	overflow: visible;

	position: fixed;
	z-index: 999999;

	background-color: #fff;
	box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
	border-radius: 8px;
	padding: 15px;

	height: 560px;
	width: 465px;
}
