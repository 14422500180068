.client-details {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
}

.client-details__header {
	display: flex;
	flex-flow: column;
	margin-bottom: 20px;
}

/*------*/
.details {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	background-color: var(--grey-50);
	border-radius: 4px;
	gap: 30px;
	padding: 20px;
	margin-top: 20px;
}

.details__general {
	flex: 2;
	width: 100%;
	flex-flow: column;
}

.details__general__input-cont {
	margin-bottom: 10px;
}

.details__general__title,
.details__more__title {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 1rem;
}

.details__more {
	width: 100%;
	flex: 2;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-start;
	align-items: flex-start;
}

.details__actions {
	margin-top: auto;
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	gap: 13px;
}

.client-details-form {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
}

/*  */

.client-details-form-normal-contact {
	flex-grow: 1;
	margin-right: 25px;
	max-width: 350px;
	margin-top: 0px;
}

.client-details-form-normal-contact h4 {
	margin: 0px;
	color: #666666;
	font-weight: 200;
	font-style: italic;
}

/*  */

.client-details-form-primary-contact {
	flex-grow: 1;
	margin-right: 25px;
	max-width: 350px;
	/*background-color: blue;*/
}

.client-details-form-primary-contact h4 {
	margin: 0px;
	color: #666666;
	font-weight: 200;
	font-style: italic;
}

.client-actions {
	align-self: flex-end;
	display: flex;
	flex-flow: row nowrap;
}

/*  */
.client-details-button-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	font-style: normal;
	cursor: pointer;
	height: 40px;
	width: 170px;
	border-radius: 30px;
	font-size: 19px;
	font-weight: 300;
	color: #395395;
	background-color: #fff;
	border: 1px solid #395395;
	margin-left: 10px;
}

.client-details-button-container:hover {
	transition: 0.2s;
	color: white;
	background-color: #395395;
	border: 1px solid #2a3e6f;
}

.client-details-flags {
	margin-top: 40px;
	display: flex;
	flex-flow: column nowrap;
}

.client-details-flag-item {
	height: 45px;
	width: 280px;
	padding-left: 10px;
	padding-right: 30px;
	/* margin-left: 10px;
    margin-top: 20px; */
	font-size: 18px;
	font-weight: 300;
	color: #666666;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	box-sizing: border-box;
}

.client-details-flag-item-input {
	margin-left: auto;
}

@media screen and (max-width: 992px) {
	.client-details-form-primary-contact {
		margin-right: 25px;
		margin-left: 0;
		max-width: 400px;
	}
}

.client-toggle-container {
	margin-top: 30px;
	margin-left: 0px;
}
