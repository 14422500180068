.tab {
	height: 35px;
	width: fit-content;
	padding: 0px 10px;
	color: #000;
	cursor: pointer;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 700;
	border: 1px solid var(--grey-300);
	display: flex;
	justify-content: center;
	align-items: center;
}

.tab:hover {
	transition: 0.2s;
	border-color: var(--company-color);
	color: var(--company-color);
}

.tab--active {
	background-color: var(--blue-100);
	color: var(--company-color);
	border-color: var(--company-color);
}

.tab--icon-n-text {
	gap: 13px;
}
