.client-group-assigner {
	width: 100%;
}

.client-group-assigner__title {
	font-weight: 700;
	font-size: 15px;
	margin-top: 20px;
}

.client-group-assigner__list {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 10px;
}

.client-group-assigner__list__item {
	min-height: 36px;
	padding: 12px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	border: 1.5px solid var(--grey-200);
}

.client-group-assigner__list__employee-name {
	font-weight: 700;
}

.client-group-assigner__list__item__employee-name {
	font-weight: 700;
	font-size: 16px;
	color: var(--primary-main);
}

.client-group-assigner__list__item__icon:hover {
	cursor: pointer;
	opacity: 0.8;
}
