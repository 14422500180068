.clients__header {
	display: flex;
	justify-content: flex-end;
	gap: 13px;
}

.clients-notes {
	margin-left: 25px;
}

.client-details-notes {
	resize: vertical;
	height: 230px;
	width: 312px;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

.client-details-datepicker,
.contact-modal-datepicker {
	width: 100%;
	height: 30px;
	padding-right: 20px;
	padding-left: 20px;
	font-size: 18px;
	color: #000;
	border-radius: 4px;
	border: 1px solid #dfdfdf;
	background: #fff;
	box-sizing: border-box;
	font-weight: 700;
}

.clients__header__item--checkbox,
.clients__row__item--checkbox {
	flex: 0.2 !important;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
	.client-details-form-primary-contact {
		margin-right: 25px;
		margin-left: 0;
		max-width: 400px;
	}
}
