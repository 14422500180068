.side-navbar-container {
	height: 100%;
	max-width: 280px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: #fcfcfc;
	overflow-x: hidden;
}

.side-navbar-items {
	display: flex;
	flex-flow: column;
	padding-bottom: 55px;
	align-items: flex-start;
	width: 100%;
}

.navbar-item-container__title {
	font-size: 12px;
	color: var(--grey-700);
	margin-bottom: 20px;
	margin-left: 30px;
	margin-top: 25px;
}

.navbar-link {
	color: var(--grey-700);
	text-decoration-line: none;

	display: flex;
	align-items: center;

	margin-bottom: 7px;
	height: 34px;
	padding-left: 19px;

	margin-right: 52px;
	margin-left: 30px;

	width: 100%;

	border-left: 3px solid transparent;
}

.navbar-link:hover {
	cursor: pointer;
	transition: 0.2s;
	background-color: var(--grey-100);
	border-left: 3px solid var(--grey-300);
}

.navbar-link--active {
	transition: 0.2s;

	border-left: 3px solid var(--blue-300);
	color: var(--company-color);
	margin-right: 10px;
}

.navbar-link--active:hover {
	background-color: transparent;
	border-left: 3px solid var(--blue-300);
}

.navbar-item-container {
	display: flex;
	flex-flow: column;
	border-bottom: 1px solid var(--grey-100) !important;
	width: 100%;
}

.navbar-item-container__item {
	display: flex;
	flex-flow: column;
	text-align: left;

	padding-bottom: 10px;
}

.navbar-link__label {
	font-weight: 700;
	margin-left: 10px;
	font-size: 14px;
}

.navbar-logo {
	padding: 31px 0px 26.5px 30px;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	border-bottom: 1px solid var(--grey-100) !important;

	width: 100%;
}

.navbar-logo__srj-logo {
	width: 50px;
}

.logo {
	width: 60px;
}

.navbar-line-breaker {
	border: 1px solid #ffffff;
	width: 180px;
	margin-top: 25px;
}

/* On screens that are 1280px or less */
@media screen and (max-width: 1280px) {
}
