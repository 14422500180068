@font-face {
	font-family: "Modern Era";
	src: url("../public/fonts/modern-era-bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Modern Era";
	src: url("../public/fonts/modern-era-regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Modern Era";
	src: url("../public/fonts/modern-era-light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

body {
	/* Custom font with fallbacks & set default font and weight */
	font-family: "Modern Era", Arial, sans-serif;
	font-weight: 400;
	background-color: #fff;
	color: --var(--primary-main);
}

input:focus,
textarea,
button {
	outline: none !important;
	font-family: "Modern Era";
}

svg {
	flex-shrink: 0;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.protected-pages {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.disable {
	opacity: 0.5;
}

.table-row:hover {
	opacity: 0.7;
	cursor: pointer;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

html,
body {
	margin: 0;
	padding: 0;
}

.modal-title {
	font-size: 28px;
	color: var(--primary-main);
	font-weight: 700;
	border-bottom: 1px solid var(--grey-200);
	padding: 20px;
}

.modal-footer {
	display: flex;
	justify-content: flex-end;
	gap: 13px;
	padding: 20px;
	border-top: 1px solid var(--grey-200);
}

.modal-content {
	display: flex;
	flex-flow: column;
	gap: 10px;
	padding: 20px;
}

/* TODO: need to move to pill.css */
.pill-container {
	display: flex;
	flex-flow: row wrap;
}

.pill-container--vertical {
	display: flex;
	flex-flow: column wrap;
}

.pill-container--filter-view {
	margin-top: 10px;
	width: 250px;
}

.pill {
	width: fit-content;
	margin-right: 10px;
	margin-bottom: 10px;

	padding: 7px 15px 7px 15px;
	color: var(--primary-main) !important;
	cursor: pointer;
	border-radius: 4px;
	font-size: 12px;
	background-color: transparent;
	font-weight: 700;
	border: 1px solid var(--grey-200);
}

.pill--active {
	background-color: var(--company-color) !important;
	color: white !important;
	transition: 0.2s;
}

.toggles {
	display: flex;
	flex-flow: row;

	margin-right: 10px;
}

.toggles__item {
	box-sizing: border-box;
	cursor: pointer;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 35px;

	margin-right: 10px;
	color: var(--primary-main);
	border: 1px solid var(--grey-200);
	border-radius: 4px;
	background-color: transparent;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
}

.toggles__item--active {
	background-color: var(--grey-200) !important;
	color: #000 !important;
	transition: 0.2s;
}

.toggle-container {
	display: flex;
	flex-flow: row;
	width: 100%;
}

.toggle-container > *:not(:last-child) {
	margin-right: 10px;
}

.container {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;

	position: relative;
}

.container-actions {
	width: 100%;
	margin-top: 10px;
	display: flex;
	flex-flow: row nowrap;
	margin-left: auto;
	align-items: center;
	justify-content: flex-end;
}

.container-header {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: space-evenly;
	text-align: left;

	width: 100%;
}

.container-header-options {
	display: flex;
	flex-flow: row nowrap;
	margin-left: auto;
	justify-items: flex-end;
	align-items: center;
	gap: 13px;
}

.container-header-title {
	margin-right: auto;
	margin-bottom: 24px;
	margin-top: 20px;
}

.container-header-title__name {
	text-align: left;
	font-size: 21px;

	font-weight: 700;
	color: #6c6c6c;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.container-header-title__name__back {
	margin-top: 5px;
	margin-right: 10px;
}

.container-header-title__sub {
	margin-bottom: 5px;
	font-size: 14px;
	color: #b6b6b6;
	text-align: left;
}

.container-header--view-all-link {
	color: #395395;
	margin-left: 10px;
}

.container-loader {
	margin-top: 100px;
	color: #666666;
	font-size: 36px;
	font-weight: 200;

	width: 100%;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}

.container-empty {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	margin-top: 100px;
	color: #666666;
	font-size: 24px;
	font-family: "Modern Era";

	font-weight: 400;

	width: 100%;
}

.container-search {
	margin-left: 10px;
	height: 40px;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 16px;
	background-color: transparent;
	font-weight: 200;
	border: 1px solid #395395;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	color: #b6b6b6;
	background-color: #fff;
	border: none;
	width: 550px;
}

.container-add {
	margin-left: 10px;
	padding-top: 4px;
	height: 30px;
	width: 35px;
	color: #000;
	cursor: pointer;
	border-radius: 4px;
	font-size: 16px;
	background-color: transparent;
	font-weight: 200;
	border: 1px solid #dfdfdf;
}

.container__body {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;

	width: 100%;
}

.btn {
	display: flex;
	align-items: center;
	height: 32px;
	color: var(--primary-main);
	font-size: 14px;
	background-color: #fff;

	border: 1px solid var(--grey-200);
	display: flex;
	align-items: center;
	border-radius: 4px;
	width: fit-content;
	padding-left: 21px;
	padding-right: 21px;
	font-family: "Modern Era";
	font-weight: 700;
}

.btn--mini {
	font-size: 14px;
	min-height: 25px;
	max-height: 25px;
	margin: 0px;
	max-width: 100px;
	min-width: 100px !important;
}

.btn--icon {
	min-width: 60px !important;
	max-width: 60px !important;
}

.btn:hover {
	cursor: pointer;
	opacity: 0.8;
	color: #fff;
	background-color: #000;
	transition: 0.2s;
}

.btn-group {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
}

.btn-group > *:not(:last-child) {
	margin-right: 15px;
	/* Adjust the margin-right value as needed */
}

.list-header {
	height: 50px !important;
	background-color: #fff !important;
	color: #000 !important;
	padding-left: 10px;
	border-bottom: 1px solid #dfdfdf;
}

.list-header:hover {
	cursor: pointer;
}

.list-item {
	color: #4a4a4a;
	background-color: white;
	height: 30px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.list-item--height {
	height: unset;
	min-height: 30px !important;
	max-height: 1000px !important;
}

.list-item:hover {
	filter: brightness(120%);
	cursor: pointer;
}

.list-item--stripe {
	background-color: #ebedf4;
}

.list-column {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
	flex: 3;
	font-weight: 700;
	color: #3f3f3f;
}

.list-column--xxs {
	flex: 1;
}

.list-column--xs {
	flex: 2;
}

.list-column--s {
	flex: 3;
}

.list-column--m {
	flex: 4;
}

.list-column--l {
	flex: 5;
}

.list-column--xl {
	flex: 6;
}

.list-column--xxl {
	flex: 7;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	/* background: #f1f1f1; */
	opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(194, 194, 194);
	border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #395395;
}

.list-load-more {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.react-responsive-modal-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.react-responsive-modal-modal {
	width: 700px;
	max-height: 100%;
	padding: 0;
	margin: 0;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}
