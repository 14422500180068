body {
	background: rgba(57, 83, 149, 0.1);
	height: 100%;
}

.settings-form {
	display: flex;
	flex-flow: row;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	height: auto;
	padding-left: 25px;
	padding-bottom: 34px;
	padding-top: 29px;
	padding-right: 25px;
	margin-top: 50px;
}

.settings-form input {
	width: 100%;
	padding-top: 13px;
	padding-bottom: 13px;
	padding-right: 20px;
	padding-left: 20px;
	font-size: 20px;
	color: #4a4a4a;
	border-radius: 30px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
}

.company-settings-subtitle {
	margin-bottom: 6px;
	font-size: 20px;
	color: #666666;
	text-align: left;
	font-weight: 300;
	margin-left: 10px;
}

.company-settings {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
}

.company-settings-header {
	text-align: left;
}

.company-settings-title {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 30px;
}

.company-settings-subtitle {
	font-weight: 200;
	margin-top: 41px;
	margin-bottom: 0;
	font-size: 20px;
	color: #b6b6b6;
	text-align: left;
	margin-left: 30px;
}

.company-settings-title-container {
	display: flex;
	flex-flow: column;
}

.company-staff-subtitle {
	font-weight: 200;
	margin-top: 41px;
	margin-bottom: 0;
	font-size: 20px;
	color: #b6b6b6;
	text-align: left;
	margin-left: 30px;
}

.company-staff-title {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 30px;
}

/*  */
.staff-info-table-header {
	display: flex;
}

/*-------*/

.company-settings-form-container {
	display: flex;
	flex-flow: column;
	justify-content: center;
	background-color: #fff;
	border-radius: 10px;
	height: auto;
	padding-left: 25px;
	padding-bottom: 30px;
	padding-right: 25px;
	padding-top: 10px;
	margin-top: 50px;
}

.company-settings-form {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
}

.company-button-container {
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 30px;
	font-style: normal;
	cursor: pointer;
	height: 50px;
	width: 300px;
	border-radius: 30px;
	font-size: 25px;
	color: #395395;
	background-color: #fff;
	border: 2px solid #395395;
}

.company-button-container:hover {
	transition: 0.2s;
	color: white;
	background-color: #395395;
	border: 2px solid #2a3e6f;
}

/*TASKS -------*/

.company-staff-title-2 h1 {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 41px;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 30px;
	text-align: left;
}

.company-staff-title-2 {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.staff-info-options {
	display: grid;
	grid-template-columns: auto auto;
}

.staff-info-option1 {
	margin-right: 15px;
}

/*-----*/

.staff-info-table {
	border-radius: 10px;
	overflow: hidden;
	perspective: 1px;
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	white-space: nowrap;

	margin-top: 32px;
	background: #ffffff;
	box-shadow: 0 0 20px -10px rgba(115, 115, 115, 0.5);
}

.staff-info-table td {
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 5px 20px;
	width: 100%;
}

/*workflow table - header*/
.staff-info-table th {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
	text-align: left;
	background-color: #fff;
	width: 100%;
}

.staff-info-table th:last-child,
.staff-info-table td:last-child {
	text-align: right;
	padding-right: 20px;
}

.staff-info-table tbody {
	max-height: calc(50vh - 225px);
	display: block;
	overflow-y: auto;
	overflow-x: hidden;
}

.staff-info-table thead {
	display: block;
	overflow-x: hidden;
}

.staff-info-table tbody tr {
	display: flex;
}

/**/

.contact-section {
	margin-top: -1em;
	margin-left: 25px;
	flex-grow: 1;
}

.contact-section h5 {
	font-size: 1.2em;
	font-weight: 300;
	text-align: left;
	color: #666666;
	margin-bottom: 10px;
}

.contact-buttons {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: baseline;
	text-align: left;
}

/*STAFF ----*/

.user-details-title {
	text-align: left;
}

.user-details-title h3 {
	font-weight: 200;
	margin-top: 41px;
	margin-bottom: 0;
	font-size: 20px;
	color: #b6b6b6;
	text-align: left;
	margin-left: 30px;
}

.user-details-title h1 {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 30px;
}

.user-password {
	color: green;

	font-weight: 100;
	font-size: 20px;
}

/*  */
.company-settings-note-container {
	margin-left: 50px;
}

/*  */
.company-plus-button {
	padding: 10px 15px 10px 15px;
	margin-top: 3em;
	color: #395395;
	cursor: pointer;
	border-radius: 10px;
	font-size: 20px;
	background-color: transparent;
	font-weight: 200;
	border: 1px solid #395395;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
}

.company-add-button:hover {
	transition: 0.3s;
	background: rgba(57, 83, 149, 0.1);
	border: 1px solid #2a3e6f;
}

/* COMPANY SETTINGS - header 'Search' input */
.company-search-input {
	margin-top: 3em;
	padding: 10px 20px 10px 20px;
	cursor: pointer;
	border-radius: 10px;
	font-size: 20px;
	background-color: transparent;
	font-weight: 200;
	border: 1px solid #395395;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	color: #b6b6b6;
	background-color: #fff;
	border: none;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
	.company-settings-form {
		display: flex;
		flex-flow: row wrap;
	}
}

.company-settings__form__file-upload {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.company-settings__form__logo-preview {
	width: 200px;
	height: 200px;
	margin-bottom: 15px;
	border: 1px solid var(--grey-200);
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
}

.company-settings__form__logo-preview:hover {
	cursor: pointer;
	opacity: 0.8;
}

.company-settings__form__file-upload__preview {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}

.company-settings__form__file-upload-input {
	display: none;
}

.company-settings__form__file-upload__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}
