/* c = container*/
.company-settings__form {
	margin-bottom: 30px;
	background-color: var(--grey-50);
	border-radius: 4px;
	padding: 20px;
}

.company-settings__form__input-c {
	margin-bottom: 10px;
	width: 100%;
}

.company-settings__form__footer {
	display: flex;
	justify-content: flex-end;
	margin-top: 30px;
}
