.relative-date {
	width: 100%;
}

.relative-date__select {
	margin-bottom: 20px;
}

.relative-date__days {
	margin-top: 10px;
	font-size: 14px;
	color: #666666;
	font-weight: 300;
	font-weight: 700;
}
