.next-modal {
}

.next-modal-blurb {
	margin-top: 50px;
	font-size: 20px;
}

.next-modal-options {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.next-modal-option {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 20px;
	padding: 10px 20px 10px 20px;
	color: #395395;
	cursor: pointer;
	border-radius: 10px;
	font-size: 20px;
	background-color: transparent;
	font-weight: 200;
	border: 1px solid #395395;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
}

.active-toggle {
	background-color: #395395 !important;
	color: white !important;
}
