.agm {
	display: flex;
	flex-flow: column;
}

.agm__blurb {
	padding: 20px;
}

.agm__flows {
	padding: 0 20px 20px;
}
