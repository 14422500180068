input[type="file"] {
	display: none;
}

.btn--file {
	border: 1px solid #ccc;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.btn--disabled {
	opacity: 0.5;
}

.recon-tool__actions {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	background-color: white;

	margin-top: 30px;
	display: flex;
	flex-flow: row nowrap;
	border-radius: 10px;
	padding: 20px 30px 20px 20px;
}

.recon-tool__actions__file-list {
	display: column;
	flex-flow: column nowrap;
}

.recon-tool__actions__file-list--empty {
	margin: 20px;
	width: 300px;

	display: flex !important;
	flex-flow: row nowrap !important;
	align-items: center;
	justify-content: center;

	font-size: 18px;
	font-style: italic;
	color: #3e3e3e;
}

.recon-tool__actions__file-list__item {
	margin: 10px 20px 20px 20px;
	width: 300px;
	height: 50px;
	border: 1px solid var(--primary-color);
	border-radius: 14px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	padding-left: 20px;

	color: var(--primary-color);
}

.recon-tables {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;

	margin-top: 20px;
}

.reconciliation-label {
	font-size: 20px;
}

.recon-tables__item {
	/* margin-right: 20px; */
	min-width: calc(50% - 10px);
	max-width: calc(50% - 10px);
}

.recon-tables__spacer {
	width: 20px;
}

.recon-tables__item__title {
	font-size: 24px;
	margin: 20px 0px;
}

.summary-container {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}

.summary-container__inputs {
	margin: 20px 0px;

	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.summary-container__inputs__spacer {
	margin-right: 20px;
}

.summary-container__summary {
	width: 100%;
}

.summary-container__actions {
	margin-top: 10px;
}

.summary-container__inputs__diff-cra {
	align-self: flex-end;

	color: var(--primary-color);
	font-size: 20px;

	padding: 15px;
	margin-left: 20px;
	border: 2px #385395 solid;
	border-radius: 10px;
}

.custom-file-upload {
	display: block;
	padding: 10px;
	cursor: pointer;
	width: calc(100% - 240px);
	/* NavBar */
	margin-left: 240px;
	/* NavBar */
	font-size: 20px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.custom-file-download {
	display: block;
	padding: 10px;
	text-decoration: none;
	color: inherit;
	font-size: 20px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.recon-tool__year-end-picker {
	margin-top: 20px;
}
