.user-assigner {
	width: 100%;
	display: flex;
	flex-flow: column;
}

.user-assigner__title {
	font-weight: 700;
	font-size: 14px;
	color: var(--primary-main);
	margin-bottom: 6px;
}

.user-assigner__list {
	display: flex;
	flex-flow: column nowrap;
	margin-top: 10px;
}

.user-assigner__list__cards {
	display: flex;
	flex-flow: column;
	gap: 20px;
	width: 100%;
}

.user-assigner__list__item {
	background-color: var(--white);
	min-height: 62px;
	padding-left: 12px;
	padding-right: 12px;
	border: 1.5px solid var(--grey-300);
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-assigner__list__item__content {
	width: 90%;
}

.user-assigner__list__item__content__divider {
	border-bottom: 1px solid var(--grey-200);
	margin: 5px 0 5px 0;
	width: 100%;
}

.user-assigner__list__item__content__user-name {
	font-size: 14px;
	color: var(--primary-main);
	font-weight: 400;
}

.user-assigner__list__item__content__icon:hover {
	cursor: pointer;
	opacity: 0.8;
}

.user-assigner__no-users {
	padding: 20px 0;
}
