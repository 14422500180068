.add-item-btn {
	display: flex;
	align-items: center;
	background-color: var(--white);
	height: 62px;
	padding-left: 12px;
	padding-right: 12px;
	border: 1.5px solid var(--company-color);

	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.add-item-btn__title {
	margin: 20px 0px;
	font-size: 14px;
	font-weight: 700;
	color: var(--company-color);
}

.add-item-btn:hover {
	cursor: pointer;
	opacity: 0.8;
}
