.custom-text-field {
	display: flex;
	flex-flow: column;
	font-family: "Modern Era";
}

.custom-text-field__label {
	font-weight: 700;
	font-size: 18px;
	color: #000000;
	margin-bottom: 13px;
}

.custom-text-field__input {
	background: #ffffff;
	width: 100%;
	font-size: 18px;
	color: #4d4d4d;
	border: none;
	border-radius: 8px;
	padding: 15px 10px;
}

.custom-text-field__input--disabled {
	margin: 0px;
}

.custom-text-field__password-view-toggle {
	background-color: transparent;
	border: none;
}

.custom-text-field__password-view-toggle:hover {
	opacity: 0.8;
	cursor: pointer;
}

.custom-text-field__input-container {
	display: flex;
	flex-flow: row;
	align-items: center;
	background: #ffffff;
	border: 1px solid var(--grey-300);
	border-radius: 8px;
	padding: 0 10px;
	position: relative;
}

.custom-text-field__input-container--disabled {
	background: rgb(237, 237, 237);
}
