.view-modal__body {
	padding: 20px;
}

.view-modal__body__checkbox-c {
	margin-top: 10px;
	margin-bottom: 10px;
}

/* view - delete modal */
.view-modal-delete__body {
	padding: 20px;
	font-size: 21px;
}
