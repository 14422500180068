.search-input {
	display: flex;
	flex-flow: column;
}

.search-input__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--grey-700);
	text-align: left;
	font-weight: 300;
	font-weight: 700;
}

.search-input__container {
	height: 35px;
	padding-left: 15px;
	border-radius: 4px;
	background-color: #fff;
	font-weight: 200;
	border: 1px solid var(--grey-300);
	color: #b6b6b6;
	min-width: 270px;
	display: flex;
	flex-flow: row;
	align-items: center;
}

.search-input__container:hover {
	transition: 0.2s;
	border-color: var(--company-color);
	color: var(--company-color);
	fill: var(--company-color);
}

.search-input__container__input {
	margin-left: 13px;
	width: 100%;
	font-size: 14px;
	border: none;
	font-weight: 700;
	font-family: "Modern Era";
}
