/*
LEGEND
eng = engagement
hd = header
*/
.eng {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
}

.eng__hd {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: space-evenly;
	text-align: left;

	width: 100%;
}

.eng__hd__title {
	margin-right: auto;
	margin-bottom: 24px;
	margin-top: 20px;
}

.eng__hd__title__name {
	text-align: left;
	font-size: 21px;
	font-weight: 700;
	color: var(--primary-main);
	display: flex;
	flex-flow: row;
	align-items: center;
}

.eng__hd__title__name__back {
	margin-top: 5px;
	margin-right: 10px;
}

.eng__hd__title__sub {
	margin-bottom: 5px;
	font-size: 14px;
	color: #b6b6b6;
	text-align: left;
}

.eng__toggle {
	display: flex;
	flex-flow: row;
	width: 100%;
	gap: 10px;
}

/* Engagement - title */
.engagement-title {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 30px;
	display: flex;
	flex-flow: row nowrap;
}

.engagement-title-back {
	margin-top: 3px;
	margin-right: 10px;
}

.engagement-title-back:hover {
	cursor: pointer;
}

/* Engagement - header container ---------------------------------------- */
.engagement-hdr-container {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

/* Engagement - title container ---------------------------------------- */
/* WORKFLOW - subtitle */
.engagement-subtitle {
	font-weight: 200;
	margin-top: 41px;
	margin-bottom: 0;
	font-size: 20px;
	color: #b6b6b6;
	text-align: left;
	margin-left: 30px;
}

/* WORKFLOW - title */
.engagement-title {
	font-size: 45px;
	color: #666666;
	font-weight: 900;
	margin-top: 0;
	padding-top: 0;
	margin-bottom: 0;
	margin-left: 30px;
}

/*  */

.engagement {
	display: flex;
	flex-flow: column;
	justify-content: center;

	background-color: var(--grey-50);
	padding: 20px;
	height: auto;
	margin-top: 30px;
}

.engagement__body {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	gap: 20px;
}

.engagement__body__input {
	margin-top: 10px;
}

.engagement__body__left {
	flex: 1;
}

.engagement__body__right {
	flex: 2;
}

.notes-container h3 {
	color: #666666;
	text-align: left;
	font-weight: 300;
}

.engagement__body__left__view-client {
	padding-top: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.engagement__body__left__view-client__link {
	text-decoration: none;
	color: var(--primary-main);
	font-size: 14px;
	font-weight: 700;
}

.engagement__body__left__view-client__link__icon {
	margin-left: 5px;
	top: 10px;
}

.eng-name {
	flex: 2;
}

.eng-name__value {
	margin-right: 0.5rem;
}

.engagement__repeats {
	display: flex;
	flex-flow: column;
	background-color: var(--grey-50);
	margin-top: 20px;
	padding: 20px;
}

.engagement__repeats__footer {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
}
