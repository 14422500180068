:root {
	--primary-main: #4a4a4a;
	--company-color: #5a7ad0;
	--company-color-rgb: 90, 122, 208;

	--white: #fff;

	--grey-50: #f7f7f7;

	--grey-100: #f1f1f1;
	--grey-200: #e9e9e9;
	--grey-500: #797979;
	--blue-400: #0062ff;
	--grey-300: #dfdfdf;
	--grey-400: #b6b6b6;
	--grey-600: #868686;
	--grey-700: #6e6e6e;

	--blue-100: #eef3ff;
	--blue-300: #d0daf4;

	--red-400: #ff0000;

	--green-400: #129e00;

	--item-opacity: 0.8;
}
