.c-s {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;

	position: relative;
}

.c-s__footer {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.s-list-eng {
	padding-left: 3px;
	width: 100%;
	height: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-left: 10px;
}

.s-list-eng:hover {
	opacity: 0.8;
	cursor: pointer;
}

.c-s-row {
	align-items: center;
	background-color: #fff;
	color: #4a4a4a;
	display: flex;
	flex-flow: row nowrap;
	padding-left: 10px;
	height: inherit;
	justify-content: flex-start;
	max-height: 200px !important;
	min-height: 30px;
	transition: box-shadow 0.3s ease-in-out 0.2s, min-height 0.3s ease-in-out 0.2s, margin-bottom 0.3s ease-in-out 0.2s, margin-top 0.3s ease-in-out 0.2s !important;
	width: 100%;
	transition: 0.2s;
	overflow-y: hidden;
}

.c-s-row:hover {
	box-shadow: 0px 0px 0px 4px var(--company-color);
	overflow: visible;
	cursor: pointer;
	min-height: 90px !important;
	max-height: fit-content !important;
	margin-top: 10px;
	margin-bottom: 10px;
	opacity: 0.9;
}

.c-s__row__item--name,
.c-s__header__item--name {
	flex-grow: 3 !important;
}

/* header */
.c-s__header {
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.c-s__header__right {
	display: flex;
	gap: 13px;
	margin-top: 20px;
	padding-top: 15px;
	border-top: 1px solid var(--grey-200);
}

.c-s__header__right__dropdowns {
	display: flex;
}

.c-s__header__right__dropdowns > *:not(:last-child) {
	margin-right: 13px;
}
