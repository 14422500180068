.staff-settings {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
}

.staff-settings__title {
	margin-bottom: 5px;
	font-size: 14px;
	color: #b6b6b6;
	text-align: left;
}

.staff-settings__header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.staff-settings__header__name {
	text-align: left;
	font-size: 16px;
	color: var(--grey-700);
	font-weight: 700;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.staff-settings__header__back {
	margin-top: 5px;
	margin-right: 10px;
}

.staff-settings__header__back:hover {
	cursor: pointer;
	opacity: 0.8;
}

.staff-settings__form {
	display: flex;
	flex-flow: column;
	background-color: var(--grey-50);
	border-radius: 4px;
	padding: 20px;
}

.staff-settings__form__input {
	margin-bottom: 10px;
}

.staff-settings__form__footer {
	display: flex;
	justify-content: flex-end;
	gap: 13px;
	margin-top: 20px;
}
