/* stm = support ticket modal */

.stm__title {
	font-size: 24px;
	color: var(--primary-main);
	font-weight: 700;
	border-bottom: 1px solid var(--grey-200);
	padding-bottom: 10px;
	margin-bottom: 20px;
}

.stm__body {
	padding: 20px;
}

/* c = container */
.stm__body__input-c {
	margin-bottom: 10px;
}

.stm__footer {
	display: flex;
	margin-top: 20px;
	justify-content: flex-end;
	gap: 20px;
}
