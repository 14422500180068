body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button,
input,
textarea {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


/* TODO: need to move this to a better place */
.rc-slider-track {
	background-color: var(--company-color) !important;
}

.rc-slider-handle {
	border-color: var(--company-color) !important;
}