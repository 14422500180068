.set-password {
	margin-top: 50px;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.set-password__body {
	width: 500px;

	@media (max-width: 768px) {
		width: 85%;
	}
}

.set-password__body__heading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.set-password__body__heading__title {
	font-weight: 700;
	font-size: 36px;
	color: #000000;
	margin-top: 66px;
}

.set-password__body__heading__subtitle {
	font-size: 19px;
	color: #797979;
	margin-top: 0;
	margin-bottom: 50px;
}

.set-password__body__form {
	display: flex;
	flex-direction: column;
}

.set-password__body__form > * {
	margin-bottom: 17px;
}

.set-password__btn {
	background: var(--company-color);
	border-radius: 8px;
	height: 54px;
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	margin-top: 20px;
	cursor: pointer;
	opacity: 1;
	margin-top: 0px;
}

.set-password__btn--disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.set-password__hr {
	margin-top: 30px;
	margin-bottom: 20px;
	border: 1px solid #e0e0e0;
}

.set-password__secondary__action {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.set-password__secondary__action__message {
	margin-right: 5px;
}

.set-password__secondary__action__link {
	color: var(--company-color);
}

.set-password__password__match {
	position: relative;
	bottom: 107px;
	transform: translateY(-50%);
	padding: 0 0px;
	z-index: 1;
}

.set-password__password__match__text {
	position: absolute;
	right: 0;
	color: green;
	gap: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
