.scrollable-container {
	display: flex;
	flex-flow: column;
	overflow-x: auto;
	width: 100%;
	height: 100%;
}

.scrollable-container__top {
	width: 100%;
	overflow-x: auto;
	scrollbar-width: thin;
	margin-bottom: -25px;
}

.scrollable-container__top__content {
	min-width: 2000px;
	height: 20px;
}

.scrollable-container__bottom {
	margin-top: 30px;
	overflow-x: auto;
	max-height: calc(95vh - 200px);
	flex-grow: 1;
	scrollbar-width: thin;
}
