.tool-card {
	margin: 10px 0px;
	padding: 20px 40px;
	height: 100%;
	border-radius: 4px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	transition: all 0.4s cubic-bezier(0.25, 0.1, 0, 1);
	background-color: white;
	border: 1px solid var(--grey-200);
}

.tool-card--is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.tool-card__icon {
	margin-top: 3px;
	align-self: flex-start;
	color: var(--company-color);
}

.tool-card__text {
	margin-left: 15px;
}

.tool-card__text__title {
	font-size: 20px;
}

.tool-card__text__blurb {
	font-size: 16px;
	margin-top: 10px;
	color: #767676;
}

.tool-card:hover,
.tool-card:focus {
	color: white !important;
	background-color: var(--company-color);
	cursor: pointer;
	transition: background 0.2s, border 0.2s, color 0.2s;
}

.tool-card:hover .tool-card__text__blurb {
	color: white !important;
	transition: background 0.2s, border 0.2s, color 0.2s;
}

.tool-card:hover .tool-card__icon {
	color: white;
	fill: white;
}
