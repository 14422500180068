.user-bubbles {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	overflow: visible;
}

.user-bubbles__item {
	border: 1px solid var(--primary-main);
	border-radius: 20px;
	color: var(--primary-main);
	padding: 2px 5px;
	width: 25px;
	margin-right: 5px;
	font-size: 10px;
	display: flex;
	flex-flow: row;
	justify-content: center;
	position: relative;
}

.user-bubbles__item:hover {
	transition: 0.2s;
	color: white;
	background-color: var(--company-color);
	border: 1px solid var(--company-color);
}

.user-bubbles__item:hover > .user-bubbles__item__card {
	display: flex;
	position: absolute;
	top: 20px;
	left: 0;
}

.user-bubbles__item__card {
	display: none;
	border: 1px solid var(--company-color);
	border-radius: 10px;
	padding: 10px;
	color: white;
	width: 250px;
	height: 50px;
	background-color: var(--company-color);
	z-index: 10000;
}

.user-bubbles__item__card__initials {
	background-color: white;
	border-radius: 50px;
	color: var(--primary-main);
	font-size: 16px;
	font-weight: 700;
	max-height: 50px;
	min-height: 50px;
	max-width: 50px;
	min-width: 50px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.user-bubbles__item__card__details {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	margin-left: 20px;
}

.user-bubbles__item__card__details__name {
	font-size: 16px;
}

.user-bubbles__item__card__details__role {
	font-size: 14px;
}
