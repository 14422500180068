.note-modal {
	padding: 0 !important;
	width: 100%;
}

.note-modal__title {
	font-size: 21px;
	color: var(--primary-main);
	font-weight: 700;
	padding: 15px;
	border-bottom: 1px solid var(--grey-200);
}

.note-modal__body {
	padding: 20px;
}

.note-modal__body__input {
	height: 100%;
	min-height: 300px !important;
}

.note-modal__footer {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-end;
	padding: 15px;
	gap: 20px;
	border-top: 1px solid var(--grey-200);
}
