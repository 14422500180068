.not-found-container {
	position: absolute !important;
	top: 50% !important;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	margin-left: 120px !important; /*Margin for left navbar*/
}

.not-found-container__title {
	font-size: 50px !important;
}

.not-found-container__message {
	font-size: 30px !important;
}
