.rrule {
	width: 600px;
	font-weight: 700;
	color: var(--primary-main);
}

.rrule__frequency-select {
	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.rrule__frequency-select__item {
	font-size: 14px;
}

.rrule__frequency {
	margin-top: 20px;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.rrule__frequency__row {
	margin: 10px 10px 10px 0px;
	gap: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.rrule__frequency__row__select {
	min-width: 15px;
	min-height: 15px;

	border-radius: 30px;

	margin-left: 20px;
	margin-right: 20px;

	background-color: white;
	border: 2px solid #395395;
}

.rrule__frequency__row__select--active {
	background-color: #395395;
	border: 2px solid #395395;
}

.rrule__frequency__row__item {
	font-size: 14px;
}

.rrule__frequency__row__input {
	margin-right: 14px;
	margin-left: 14px;
}

.rrule__end {
	margin-top: 10px;
	gap: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.rrule__end__item {
	font-size: 14px;
}
