.fields {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	color: var(--primary-main);
}

.fields__item {
	padding: 5px 30px;
	width: calc(100% - 60px);
	background-color: var(--white);
	border: 1px solid var(--primary-main);
	border-radius: 4px;
	min-height: 45px;
	margin-bottom: 20px;
	display: flex;
	flex-flow: column nowrap;
	font-weight: 700;
}

.fields__item:hover {
	cursor: pointer;
	opacity: 0.8;
}

.fields__item__details {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	height: 50px;
}

.fields__item__details__item {
	margin-right: 20px;
}

.fields__item__details__actions {
	margin-left: auto;
}

.fields__item__details__actions:hover {
	cursor: pointer;
}

.fields__item__states {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
	margin: 20px 0px;
}

.flow__item__states__item {
	font-size: 14px;
	height: 30px;
	border-radius: 5px;
	padding: 0px 15px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	margin: 3px 0px;
}
