.eng-modal {
}

.eng-modal__body {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	padding: 20px;
}

.eng-modal__body__title {
	font-size: 28px;
	color: var(--primary-main);
	font-weight: 700;
	border-bottom: 1px solid var(--grey-200);
}

.eng-modal__body__fields {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	margin-bottom: 20px;
	width: 100%;
	gap: 15px;
}

.eng-modal__body__settings__tabs {
	margin-bottom: 20px;
}

.eng-modal__body__fields__container {
	display: flex;
	flex-flow: column;
	gap: 10px;
	width: 100%;
}

.eng-modal-datepicker-container {
	display: flex;
	flex-flow: column;
}

/* TODO: will be resolved in datepicker pr */
.eng-modal-datepicker-title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.eng-modal-datepicker {
	padding: 0 15px;
	height: 31.6px;
	width: 100%;
	font-size: 14px;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: var(--white);
	box-sizing: border-box;
	font-weight: 700;
	font-family: "Modern Era";
	margin-bottom: 10px;
}

.eng-modal__date-selector {
	margin-bottom: 10px;
}

/*  */

.contacts-modal-input-box-container {
	margin-top: 20px;
}

.eng-modal__body__settings {
	margin-top: 20px;
}

.eng-modal__body__settings__divider {
	margin: 30px 0px 10px 0px;
	width: 100%;
	height: 2px;
	background-color: rgba(163, 163, 163, 0.1);
}

.eng-modal__body__settings__tasks {
	display: flex;
	flex-flow: column;
	gap: 20px;
}

.eng-modal__body__settings__tasks__list {
	margin-top: 10px;
	width: 100%;
	gap: 20px;
	display: flex;
	flex-flow: column;
}

.eng-modal__body__settings__repeats__section {
	width: 50%;
}
