.input-field {
	display: flex;
	flex-flow: column;
	width: 100%;
}

.input-field__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 700;
}

.input-field__input {
	width: 100%;
	padding-left: 12px;
	height: 35px;
	font-size: 14px;
	color: #000;
	border-radius: 4px;
	border: 1px solid var(--grey-200);
	background: var(--white);
	box-sizing: border-box;
	font-weight: 700;
	font-family: "Modern Era";
}
