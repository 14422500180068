.reset-password {
	margin-top: 50px;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.reset-password__form {
	width: 500px;

	@media (max-width: 768px) {
		width: 85%;
	}
}

.reset-password__form__heading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.reset-password__form__heading__title {
	font-weight: 700;
	font-size: 36px;
	color: #000000;
	margin-top: 66px;
}

.reset-password__form__heading__subtitle {
	font-size: 19px;
	color: var(--grey-500);
	margin-top: 0;
	margin-bottom: 50px;

	@media (max-width: 768px) {
		font-size: 17px;
	}
}

.reset-password__form__btn {
	background: var(--company-color);
	border-radius: 8px;
	height: 54px;
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-size: 18px;
	font-weight: 700;
	margin-top: 20px;
}

.reset-password__form__btn--email-sent {
	margin-top: 0 !important;
}

.reset-password__form__btn:hover {
	cursor: pointer;
	opacity: 0.9;
}

.reset-password__form__btn--disabled {
	background: var(--blue-400);
	border-radius: 8px;
	height: 54px;
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: center;
	color: white;
	font-size: 18px;
	font-weight: 700;
	margin-top: 20px;
	opacity: 0.5;
}

.reset-password__form__btn--disabled:hover {
	cursor: not-allowed;
}

.reset-password__form__hr {
	margin-top: 30px;
	margin-bottom: 20px;
	border: 1px solid var(--grey-200);
}

.reset-password__form__secondary__action {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
}

.reset-password__form_secondary__action__message {
	margin-right: 10px;
}

.reset-password__form__secondary__action__link {
	color: var(--company-color);
	margin-left: 7px;
}
