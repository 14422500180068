/*board styles override*/
.react-trello-board {
	height: 100% !important;
	background-color: transparent !important;
}

.re-parent {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.re-parent__editor {
	margin-top: 30px;
}

.re-parent__board {
	flex: 1;
	padding-left: 30px;
	padding-right: 30px;
}

.eng-tasks {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	margin-bottom: 10px;
}

/* React Trello styles override */
.re-parent__board .smooth-dnd-container.horizontal .smooth-dnd-draggable-wrapper {
	width: 100%;
}

.re-parent__board .smooth-dnd-container.horizontal .smooth-dnd-draggable-wrapper section > div {
	width: 100%;
}
