/* pcm = plaid-connection-modal */

.pcm__body {
	padding: 20px;
	font-size: 18px;
}

.pcm__status {
	margin: 10px 0px;
}

.pcm__link {
}

.pcm__accounts {
	margin: 10px 0px;
}

.pcm__accounts__item {
}
