.team__members {
	min-height: 400px;
}

.team__members__sub {
	font-size: 18px;
	margin-top: 20px;
}

.team__members__list {
	display: flex;
	flex-flow: column;

	gap: 10px;
}

.team__members__title {
	margin-top: 20px;
	font-weight: 700;
	margin-bottom: 10px;
}

.team__members__list__item {
	height: 52px;
	padding-left: 12px;
	padding-right: 12px;

	width: calc(100% - 40px);
	display: flex;

	justify-content: space-between;
	align-items: center;

	border: 1.5px solid #dfdfdf;
	font-weight: 700;
}

.team__members__list__item__action {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	margin-left: auto;

	width: 40px;
	height: 40px;

	border: 1px solid #395395;
	border-radius: 10px;
}

.team__members__list__item__action:hover {
	background-color: #395395;
	color: white;
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.team-modal__body {
	display: flex;
	flex-flow: column;
	padding: 20px;
	gap: 10px;
}

.team__members__list__item__txt {
	display: flex;
	align-items: center;
	gap: 20px;
}
