.view-list {
	position: relative;
	margin-top: 30px;
	margin-right: 25px;
}

.view-list__body {
	display: flex;
	flex-flow: column;

	background-color: var(--white);
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	/* height: 80vh; */
	max-height: 500px;
	overflow-y: scroll;
}

.view-list__body__unsaved {
	width: 100%;
	border-radius: 10px;
	border: 3px solid var(--primary-color);
	margin-bottom: 20px;
}

.view-list__body__unsaved__blurb {
	font-size: 16px;
	text-align: center;
	font-weight: 700;
	color: #de624c;
	display: flex;
	flex-flow: column;

	justify-content: flex-start;
	text-align: left;
}

.view-list__body__unsaved__blurb__icon {
	margin-bottom: 5px;
}

.view-list__body__unsaved__btn {
	width: auto;
	margin-top: 10px;
}

.view-list__body__unsaved__actions {
	margin-right: 10px;
	width: 100%;
}

.view-list__arrow {
	position: absolute;
	right: -20px;
	top: 5px;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	width: 40px;
	height: 40px;
	background-color: white;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.9);
	border-radius: 50px;
}

.view-list__arrow:hover {
	cursor: pointer;
	transition: 0.2s;
	color: var(--company-color);
}

.view-list__body__title {
	font-size: 28px;
	padding: 10px;
	color: #666666;
}

.view-list__body__actions {
	margin-top: auto;
	align-self: flex-end;
}

.view-list__body__list {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 20px;
	width: 100%;
}

.view-list__body__list__title {
	font-size: 16px;
	color: black;
	margin-bottom: 10px;
}

.view-list__body__list__item {
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	width: 100%;
	height: 38px;
	border-radius: 4px;
	padding: 0 12px;
	font-size: 14px;
	font-weight: 700;
	color: var(--primary-main);
	flex-shrink: 0;
}

.view-list__body__list__item > div {
	max-width: 80%;
	overflow: hidden;
	/* Ensure overflow is hidden */
	text-overflow: ellipsis;
	/* Add ellipsis for overflowing text */
	white-space: nowrap;
}

.view-list__body__list__item:hover {
	transition: 0.2s;
	color: var(--company-color);
	background-color: var(--blue-100);

	cursor: pointer;
}

.view-list__body__list__item--selected {
	color: var(--company-color);
	background-color: var(--blue-100);

	font-weight: 700;
}

.view-list__body__list__item__action {
	display: none;
}

.view-list__body__list__item:hover > .view-list__body__list__item__action {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	padding-top: 1px;
	width: 24px;
	height: 24px;
}

.view-list__table-view__title {
	margin-bottom: 10px;
}

.view-list__divider {
	border-top: 1px solid var(--grey-200);
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}
