.role-picker {
	display: flex;
	align-items: center;
	position: relative;
	font-size: 16px;
	width: 100%;
}

.role-picker__input {
	min-width: 100px;
	max-width: 200px;

	margin-left: 5px;

	cursor: pointer;

	font-size: 14px;
	color: var(--primary-main);
	border: none;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: row nowrap;

	font-weight: 700;
	font-family: "Modern Era";
}

.role-picker__list {
	position: absolute;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;

	top: 25px;
	left: 10px;
	max-height: 400px;
	width: 100%;

	z-index: 10;

	background-color: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 10px;

	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	list-style-type: none;
	cursor: pointer;
	overflow: scroll;
	overflow-x: hidden;
	margin-left: -10px;
}

.role-picker__list__item {
	margin-bottom: 4px;
	padding: 10px 15px;
	width: calc(100% - 30px);
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	font-weight: 700;
}

.role-picker__list__item:hover {
	transition: 0.2s;
	color: #000;
	background-color: #ececec;
}

.role-picker__title {
	font-weight: 700;
	color: #666666;
}
