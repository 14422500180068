.select {
	display: flex;
	flex-flow: column;
	width: 100%;
	position: relative;
}

.select__title {
	margin-bottom: 6px;
	font-size: 14px;
	color: var(--primary-main);
	text-align: left;
	font-weight: 300;
	font-weight: 700;
}

.select__options--no-title {
	top: 55px !important;
}

.select__options {
	position: absolute;

	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;

	top: 100px;
	left: 10px;
	max-height: 400px;
	width: 100%;
	z-index: 10;

	padding: 10px 10px 10px 10px;

	font-size: 16px;
	font-weight: 700;

	background-color: #fff;
	border: 1px solid #f5f5f5;
	border-radius: 10px;

	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
	list-style-type: none;
	cursor: pointer;
	overflow: scroll;
	overflow-x: hidden;
	margin-left: -10px;
}

.select__options__item {
	margin-bottom: 4px;
	padding: 3px 15px 3px 15px;

	border-radius: 4px;

	min-height: 28px;
	width: calc(100% - 30px);

	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
}

.select__options__item--hover {
	transition: 0.2s;
	color: #000;
	background-color: #ececec;
}
