.engagement-filter-modal {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	min-width: 100px;
	max-height: 500px;
	min-height: 500px;
	padding: 20px;
}

.assigned-flow-state {
	width: 100%;
}

/* TODO: too long classnames */
.engagement-filter-modal__body__search {
	display: flex;
	flex-flow: row;
	align-items: flex-end;
	gap: 10px;
	flex: 1;
}

.engagement-filter-modal__body__search__s-d-container {
	flex: 10;
}

.engagement-filter-modal__body__search__select-all-btn {
	height: 30px;
	flex: 3;
}

.assigned-flow-state__title,
.assigned-team__title {
	margin-top: 20px;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 700;
}

.assigned-flow-state__item,
.assigned-team__item {
	min-height: 36px;
	font-weight: 700;
	padding: 12px;
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
	background: white;
	border: 1.5px solid var(--grey-200);
}

.assigned-flow-type__item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	min-height: 36px;
	border-radius: 4px;
	padding: 12px;
	border: 1px solid var(--grey-200);
	margin-bottom: 10px;
	font-weight: 700;
}

.assigned_flow-type__item__action {
	margin-right: 15px;
	border-radius: 10px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.assigned-flow-state__item {
	margin-bottom: 10px;
}

.assigned-flow-state__item__action,
.assigned-team__item__action {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	width: 30px;
	height: 30px;
	border: 1px solid var(--primary-main);
	border-radius: 4px;
}

.assigned-flow-state__item__action:hover,
.assigned-team__item__action:hover {
	cursor: pointer;
	opacity: 0.8;
}

.line-break {
	border: 2px solid #39539551;
	width: calc(100% - 50px);
	margin-top: 25px;
	align-self: center;
}

.engagement-filter-modal__label {
	border-bottom: 1px solid var(--grey-200);
	padding-bottom: 10px;
	margin-bottom: 20px;
	font-size: 16px;
	color: #666666;
	text-align: left;
	font-weight: 400;
}

.engagement-filter-modal__menu {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	width: 200px !important;
	overflow: auto;
}

.engagement-filter-modal__menu__item {
	box-sizing: border-box;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 40px;
	border-radius: 4px;
	padding: 0 15px;
	font-size: 14px;
	color: #666666;
	font-weight: 700;
}

.engagement-filter-modal__menu__item--selected {
	color: var(--primary-main);
	font-weight: 700;
	background-color: var(--grey-200);
}

.engagement-filter-modal__menu__item:hover {
	transition: 0.2s;
	color: var(--primary-main);
	cursor: pointer;
}

.engagement-filter-modal__body {
	margin-left: 40px;
	width: 100%;
	height: 500px;
	flex: 1;
	overflow: scroll;
}

.engagement-filter-modal__modal {
	min-width: 1000px !important;
	max-width: 1000px !important;
}
