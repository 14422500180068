.users {
	padding-left: 270px;
	padding-right: 25px;
	padding-bottom: 30px;
}

.users__tabs {
	display: flex;
	gap: 13px;
	position: absolute;
}

.users__user-view__header,
.teams__team-view__header {
	display: flex;
	justify-content: flex-end;
	gap: 13px;
}

.users__user-view__header__plus-icon {
	margin-top: 4px;
}

.users__tab-txt {
	margin-left: 13px;
}
