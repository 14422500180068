#modal-container {
	background-color: #fff;
	/* min-width: 922px; */
	width: auto;
	border-radius: 25px;
	z-index: 99999999;
}

/**/

#modal-container-2 {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	width: 455px;
	height: 760px;
	border-radius: 10px;
}

#modal-title-2 {
	font-size: 40px;
	color: #666666;
	font-weight: 900;
	margin-top: 0px;
	margin-bottom: -3em;
}

/**/

#modal-title {
	font-size: 38px;
	color: #666666;
	font-weight: 900;
	margin-left: 28px;
	margin-top: 0px;
	margin-bottom: -2em;
}

#modal-close-button {
	margin-right: 1em;
	font-size: 1em;
}

#modal-form {
	display: flex;
	flex-flow: column;
}

#modal-form-container-1 {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

#modal-form-item-1 {
	display: flex;
	flex-flow: column;
}

#modal-form-item-2 {
	display: flex;
	flex-flow: column;
	margin-left: 28px;
}

#modal-form-item-3 {
	display: flex;
	flex-flow: column;
}

#modal-input-title {
	color: #666666;
	font-weight: 200;
	text-align: left;
	margin-left: 10px;
	margin-top: 20px;
	margin-bottom: 0;
}

#modal-input-1 {
	padding-top: 0;
	height: 50px;
	width: 300px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 30px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

#modal-input-description {
	resize: vertical;
	height: 135px;
	width: 312px;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	border: none;
	background: rgba(57, 83, 149, 0.1);
	font-size: 18px;
	font-weight: 200;
	color: #666666;
}

#modal-footer-button {
	/* float: right; */
	width: auto;
	min-width: 300px;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 10px;
	padding-bottom: 10px;
	/* margin-top: -15px; */
	margin-bottom: 30px;
	/* margin-right: 25px; */
	font-size: 22px;

	cursor: pointer;
	border-radius: 30px;
	color: #395395;
	background-color: #fff;
	border: 2px solid #395395;
}

.notify-checkbox {
	display: flex;
	flex-flow: row nowrap;
	font-weight: 200;
	font-size: 14px;
	margin-left: 110px;
}
